import React, { useEffect, useState } from 'react';
import { StudentType } from '../../types/data';
import { formatNumberToNaira } from '../../functions/stringManipulations';
import { appAxios } from '../../api/axios';

const ReferralInfo = ({ user }: { user: StudentType }) => {
  const [referralsBalanceStats, setReferralsBalanceStats] = useState<number>(0);
  const amount_transferred = (user?.referralEarnings - referralsBalanceStats) || 0;

  useEffect(() => {
    const getData = async () => {
      try {
        const referralsBalanceResponse = await appAxios.get(`/accounting/referrals/balance?owner=${user._id}`);
        if (referralsBalanceResponse && referralsBalanceResponse.data && referralsBalanceResponse.data.data) {
          setReferralsBalanceStats(referralsBalanceResponse.data.data);
        }
      } catch (error) {
        console.log(error);
      } finally {
      }
    };

    getData();
  }, [user])

  return (
    <div className='w-full bg-[#F0F7FF] rounded-md px-6 py-5'>
      <h2 className='font-semibold text-lg md:text-xl mb-1 text-[#272835]'>
        Referral Info
      </h2>
      <p className='text-[#828282] mb-4 text-sm'>Details on user's referral history</p>

      <p>
        <b>Number of referrals:</b> <span>{user.numOfReferrals}</span>
      </p>
      <p>
        <b>Earnings:</b> <span>{formatNumberToNaira(user.referralEarnings)}</span>
      </p>

      <p>
        <b>Amount Transferred:</b> <span>{formatNumberToNaira(amount_transferred)}</span>
      </p>

      <p>
        <b>Referral Balance:</b> <span>{formatNumberToNaira(referralsBalanceStats || 0)}</span>
      </p>
    </div>
  );
};

export default ReferralInfo;
