import React, {useState} from 'react';
import { DeleteIcon } from '../../../../icons';
import Checkbox from '../../../../../common/Checkbox';
import {addStyles, EditableMathField } from 'react-mathquill';

addStyles();

const Option = ({
    option, index, removeOption, handleOnChangeOption, 
    handleOnChangeOptionCorrectAnswer
}) => {
  const [latex, setLatex] = useState(option?.option_value || '');

    return <div key={index}>
        <div className='mb-2 flex items-center gap-5'>
            <p className='font-bold'>Option {index + 1}</p>
            <button
                type='button'
                className='border-none outline-none'
                onClick={() => removeOption(index)}
            >
                <DeleteIcon />
            </button>
        </div>
        
        <EditableMathField
            latex={latex}
            onChange={(mathField) => {
                setLatex(mathField.latex())
                handleOnChangeOption({ option_value: latex }, index)
            }}
        />

        <Checkbox
            label='Correct Answer'
            id={'isCorrectAnswer' + index}
            value={String(option.isCorrectAnswer || false)?.toString()}
            checked={option.isCorrectAnswer || false}
            onChange={
                (e: React.ChangeEvent<HTMLInputElement>) =>
                handleOnChangeOptionCorrectAnswer(index, e.target.checked)
            }
        />
    </div>
}

export default Option;