import {addStyles, StaticMathField } from 'react-mathquill';
import CustomModal from '../../../../common/CustomModal/CustomModal';
import { TestType } from '../../../../types/data';

addStyles();

interface Props {
  closeModal: () => void;
  open: boolean;
  data: TestType | undefined;
}

function ViewModal({ closeModal, data, open }: Props) {
  if (!data) return null;

  const text_link = {
    color: 'rgb(29 78 216 / var(--tw-bg-opacity)) !important',
    cursor: 'pointer',
    textDecoration: 'underline'
  }

  const handleQuestionFile = () => {
    const fileUrl: string = data?.question?.fileUrl;
    if (!fileUrl) {
      return;
    }
    window.open(fileUrl, '_blank');
  }

  const handleSolutionFile = () => {
    const fileUrl: string = data?.solution?.fileUrl;
    if (!fileUrl) {
      return;
    }
    window.open(fileUrl, '_blank');
  }

  return (
    <CustomModal
      isOpen={open}
      onRequestClose={closeModal}
      title='Question Details'
      width='600px'
      shouldCloseOnOverlayClick={false}
    >
      {Object.keys(data).length ? (
        <>
          <p className='capitalize mb-3 pb-2 border-b-2'>
            <b>Title:</b> {data.title}
          </p>
          {
            data?.question?.fileUrl &&
            <p className='capitalize mb-3 pb-2 border-b-2'>
              <b>View Question File: </b> 
              <span style={text_link} onClick={handleQuestionFile}>
                Link To Question File
              </span>
            </p>
          }
          <p className='capitalize mb-3 pb-2 border-b-2'>
            <b>Question Type:</b> {data.question_type}
          </p>
          {data.question_type === 'input' && (
            <p className='capitalize mb-3 pb-2 border-b-2'>
              <b>Question Option Type:</b> {data.question_input_type}
            </p>
          )}
          <p className='capitalize mb-3 pb-2 border-b-2'>
            <b>Total Options:</b> {data.options.length}
          </p>

          <p className='capitalize mb-3 '>
            <b>Options:</b>
          </p>
          <ol className='flex flex-col gap-3 w-full border-b-2 pb-2 mb-3 list-inside list-lower-alpha px-3'>
            {data.options?.map((item) => (
              <li
                key={item._id}
                className={item.isCorrectAnswer ? "after:content-['_✓']" : ''}
                style={{
                  color: item.isCorrectAnswer ? 'var(--success)' : '#000',
                  fontWeight: item.isCorrectAnswer ? '600' : '300',
                }}
              >
                <StaticMathField>{item.option_value}</StaticMathField>
              </li>
            ))}
          </ol>
          {
            data?.solution?.fileUrl &&
            <p className='capitalize mb-3 pb-2 border-b-2'>
              <b>View Solution File: </b> 
              <span style={text_link} onClick={handleSolutionFile}>
                Link To Solution File
              </span>
            </p>
          }
          <p className='capitalize mb-3 pb-2 border-b-2'>
            <b>Created At:</b> {new Date(data.creation_date).toDateString()}
          </p>
          <p className='capitalize mb-3 pb-2 border-b-2'>
            <b>Updated At:</b> {new Date(data.last_update_date).toDateString()}
          </p>
        </>
      ) : (
        <>No detail found</>
      )}
    </CustomModal>
  );
}

export default ViewModal;
