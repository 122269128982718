import AccountingPage from '../../pages/accounting';
import { PrivateRoute } from '../utils';

const accountingRoutes = [
  {
    path: '/accounting',
    element: (
      <PrivateRoute>
        <AccountingPage />
      </PrivateRoute>
    ),
  },
];

export default accountingRoutes;
