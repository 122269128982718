import AppLayout from '../../layout/AppLayout';
import SummaryCard from '../../components/dashboard/SummaryCard';
import { useEffect, useState } from 'react';
import { appAxios } from '../../api/axios';
import { sendCatchFeedback, sendFeedback } from '../../functions/feedback';
import { SubscriptionStat, ReferralAmountStat, SubscriptionPlan } from '../../types/data';

const AccountingPage = () => {
  const [subscriptionloading, setSubscriptionLoading] = useState(false);
  const [subscriptionMonthlyLoading, setSubscriptionMonthlyLoading] = useState(false);
  const [subscriptionQuarterlyLoading, setSubscriptionQuarterlyLoading] = useState(false);
  const [subscriptionYearlyLoading, setSubscriptionYearlyLoading] = useState(false);
  const [referralsTotalLoading, setReferralsTotalLoading] = useState(false);
  const [referralsAmountLoading, setReferralsAmountLoading] = useState(false);
  const [referralsBalanceLoading, setReferralsBalanceLoading] = useState(false);
  const [subscriptionStats, setSubscriptionStats] = useState<SubscriptionStat | null>(null);
  const [referralsTotalStats, setReferralsTotalStats] = useState<number | null>(null);
  const [referralsAmountStats, setReferralsAmountStats] = useState<ReferralAmountStat | null>(null);
  const [referralsBalanceStats, setReferralsBalanceStats] = useState<number | null>(null);
  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>([]);
  const [subscriptionMonthlyStats, setSubscriptionMonthlyStats] = useState<SubscriptionStat | null>(null);
  const [subscriptionQuarterlyStats, setSubscriptionQuarterlyStats] = useState<SubscriptionStat | null>(null);
  const [subscriptionYearlyStats, setSubscriptionYearlyStats] = useState<SubscriptionStat | null>(null);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [filter, setFilter] = useState('');
  const [showFilter, setShowFilter] = useState(false);
  const referralPaidStat = (referralsAmountStats?.totalReferralEarning || 0) - (referralsBalanceStats || 0);

  const getSubscriptionPlans = async () => {
    try {
      const subscriptionPlansResponse = await appAxios.get(`/payment/fetch-subscription-plans`);
      if (subscriptionPlansResponse && subscriptionPlansResponse.data && subscriptionPlansResponse.data.data) {
        setSubscriptionPlans(subscriptionPlansResponse.data.data);
      }
    } catch (error) {
      sendCatchFeedback(error);
    } finally {}
  }

  useEffect(() => {
    const getData = async () => {
      try {
        setSubscriptionLoading(true);
        setReferralsTotalLoading(true);
        setReferralsAmountLoading(true);
        setReferralsBalanceLoading(true);
  
        const subscriptionResponse = await appAxios.get(`/accounting/subscriptions/total?${filter}`);
        if (subscriptionResponse.data && subscriptionResponse.data.data && subscriptionResponse.data.data[0]) {
          setSubscriptionStats(subscriptionResponse.data?.data[0]);
          setSubscriptionLoading(false);
        }
  
        const referralsTotalResponse = await appAxios.get(`/accounting/referrals/total?${filter}`);
        if (referralsTotalResponse && referralsTotalResponse.data && referralsTotalResponse.data.data) {
          setReferralsTotalStats(referralsTotalResponse.data.data);
          setReferralsTotalLoading(false);
        }
  
        const referralsAmountResponse = await appAxios.get(`/accounting/referrals/amount?${filter}`);
        if (
            referralsAmountResponse.data && referralsAmountResponse.data.data 
            && referralsAmountResponse.data.data[0]
          ) {
          setReferralsAmountStats(referralsAmountResponse.data?.data[0]);
          setReferralsAmountLoading(false);
        }
  
        const referralsBalanceResponse = await appAxios.get(`/accounting/referrals/balance?${filter}`);
        if (referralsBalanceResponse && referralsBalanceResponse.data && referralsBalanceResponse.data.data) {
          setReferralsBalanceStats(referralsBalanceResponse.data.data);
          setReferralsBalanceLoading(false);
        }
      } catch (error) {
        sendCatchFeedback(error);
      } finally {
        setSubscriptionLoading(false);
        setReferralsTotalLoading(false);
        setReferralsAmountLoading(false);
        setReferralsBalanceLoading(false);
      }
    };
    
    getData();
  }, [filter]);

  useEffect(() => {
    const getDataBySubscriptionPlans = async () => {
      try {
        if (!subscriptionPlans.length) return;
  
        setSubscriptionMonthlyLoading(true);
        setSubscriptionQuarterlyLoading(true);
        setSubscriptionYearlyLoading(true);
  
        const monthlyPlan = subscriptionPlans.filter(plan => plan.subscriptionPlan === 'monthly');
        const quarterlyPlan = subscriptionPlans.filter(plan => plan.subscriptionPlan === 'quarterly');
        const yearlyPlan = subscriptionPlans.filter(plan => plan.subscriptionPlan === 'yearly');
  
        const subscriptionMonthlyPlanResponse = await appAxios.get(
          `/accounting/subscriptions/total?planId=${monthlyPlan[0]._id}&${filter}`
        );
  
        if (
          subscriptionMonthlyPlanResponse.data && subscriptionMonthlyPlanResponse.data.data && 
          subscriptionMonthlyPlanResponse.data.data[0]
        ) {
          setSubscriptionMonthlyStats(subscriptionMonthlyPlanResponse.data?.data[0]);
          setSubscriptionMonthlyLoading(false);
        } else {
          setSubscriptionMonthlyStats({totalRevenue: 0, _id: 'totalRevenue'});
          setSubscriptionMonthlyLoading(false);
        }
  
        const subscriptionQuarterlyPlanResponse = await appAxios.get(
          `/accounting/subscriptions/total?planId=${quarterlyPlan[0]._id}&${filter}`
        );
        
        if (
          subscriptionQuarterlyPlanResponse.data && subscriptionQuarterlyPlanResponse.data.data && 
          subscriptionQuarterlyPlanResponse.data.data[0]
        ) {
          setSubscriptionQuarterlyStats(subscriptionQuarterlyPlanResponse.data?.data[0]);
          setSubscriptionQuarterlyLoading(false);
        } else {
          setSubscriptionQuarterlyStats({totalRevenue: 0, _id: 'totalRevenue'});
          setSubscriptionQuarterlyLoading(false);
        }
        
        const subscriptionYearlyPlanResponse = await appAxios.get(
          `/accounting/subscriptions/total?planId=${yearlyPlan[0]._id}&${filter}`
        );
        
        if (
          subscriptionYearlyPlanResponse.data && subscriptionYearlyPlanResponse.data.data && 
          subscriptionYearlyPlanResponse.data.data[0]
        ) {
          setSubscriptionYearlyStats(subscriptionYearlyPlanResponse.data?.data[0]);
          setSubscriptionYearlyLoading(false);
        } else {
          setSubscriptionYearlyStats({totalRevenue: 0, _id: 'totalRevenue'});
          setSubscriptionYearlyLoading(false);
        }
      } catch (error) {
        sendCatchFeedback(error);
      } finally {
        setSubscriptionMonthlyLoading(false);
        setSubscriptionQuarterlyLoading(false);
        setSubscriptionYearlyLoading(false);
      }
    };

    getDataBySubscriptionPlans()
  }, [subscriptionPlans, filter]);

  useEffect(() => {
    getSubscriptionPlans();
  }, []);

  const filterByPeriod = (e) => {
    if (!startDate || !endDate) {
      sendFeedback('You must provide a start date and an end date.', 'error');
    } else {
      setFilter(`start_date=${startDate}&end_date=${endDate}`);
    }
  }

  const clearFilter = (e) => {
    setStartDate('');
    setEndDate('');
    setFilter('');
    setShowFilter(false);
  }

  return (
    <AppLayout>
      <div className='mb-5 flex items-center justify-between'>
        <strong>Accounting Module</strong>

        <strong 
          style={
            {cursor: 'pointer', color: 'blue'}
          }
          onClick={() => setShowFilter(!showFilter)}>
          Filter
        </strong>
      </div>

      {showFilter ? <div className='mb-3'>
        <p className='mb-1'>Filter By Period: </p>
        <div className='flex items-center justify-between filter-by-period'>
          <div>
            <input 
              type="date" name="" 
              id="start-date-filter"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)} 
            /> 
            <span> Start Date</span> 
          </div> 
          <div>
            <input 
              type="date" name="" 
              id="end-date-filter"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)} 
            /> 
            <span> End Date</span>
          </div>
          <button 
            className='bg-primary text-white rounded-lg p-4 hover:brightness-110 duration-300 font-medium'
            onClick={filterByPeriod}
          > 
            Apply Filter
          </button>

          <button 
            className='bg-secondary rounded-lg p-4 hover:brightness-110 duration-300 font-medium'
            onClick={clearFilter}
          > 
            Clear Filter
          </button>
        </div>
      </div> : null}

      <p className='mb-2'>Subscriptions Revenue</p>

      <div className='bg-white p-5 grid grid-cols-1 md:grid-cols-2 rounded-[10px] gap-y-10'>
        <SummaryCard
          value={subscriptionStats?.totalRevenue || 0}
          loading={subscriptionloading}
          label='Total Generated Revenue Amount'
          showBorder={true}
          key={'total-generated-revenue-amount'}
          money={true}
        />

        <SummaryCard
          value={subscriptionMonthlyStats?.totalRevenue || 0}
          loading={subscriptionMonthlyLoading}
          label='Total Generated Revenue Amount - Monthly Plan'
          showBorder={true}
          key={'total-generated-revenue-amount-monthly-plan'}
          money={true}
        />

        <SummaryCard
          value={subscriptionQuarterlyStats?.totalRevenue || 0}
          loading={subscriptionQuarterlyLoading}
          label='Total Generated Revenue Amount - Quarterly Plan'
          showBorder={true}
          key={'total-generated-revenue-amount-quarterly-plan'}
          money={true}
        />

        <SummaryCard
          value={subscriptionYearlyStats?.totalRevenue || 0}
          loading={subscriptionYearlyLoading}
          label='Total Generated Revenue Amount - Yearly Plan'
          showBorder={true}
          key={'total-generated-revenue-amount-yearly-plan'}
          money={true}
        />
      </div>

      <p className='mb-2 mt-5'>Referral Statistics</p>
      
      <div className='bg-white p-5 grid grid-cols-1 md:grid-cols-2 rounded-[10px] gap-y-10'>
        <SummaryCard
          value={referralsTotalStats || 0}
          loading={referralsTotalLoading}
          label='Total Number of Successful Referrals'
          showBorder={true}
          key={'total-successful-referrals-number'}
        />

        <SummaryCard
          value={referralsAmountStats?.totalReferralEarning || 0}
          loading={referralsAmountLoading}
          label='Total Referrals Revenue Amount'
          showBorder={true}
          key='total-referrals-revenue-amount'
          money={true}
        />

        <SummaryCard
          value={referralPaidStat}
          loading={referralsBalanceLoading}
          label='Total Referrals Amount Paid Out'
          showBorder={true}
          key='total-referrals-paid-amount'
          money={true}
        />

        <SummaryCard
          value={referralsBalanceStats || 0}
          loading={referralsBalanceLoading}
          label='Total Referrals Balance Amount'
          showBorder={true}
          key='total-referrals-balance-amount'
          money={true}
        />
      </div>
    </AppLayout>
  )
}

export default AccountingPage